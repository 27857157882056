var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"edit-category"},[_c('div',{staticClass:"page-header"},[_c('h3',{staticClass:"page-title"},[_vm._v("Add Flat Rate")]),_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{staticClass:"text-primary",attrs:{"to":"/settings/flat-rate"}},[_vm._v(" Flat Rate ")])],1),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v("Add Flat Rate")])])])]),_c('form',{staticClass:"pt-2",on:{"submit":function($event){$event.preventDefault();return _vm.addFlatRate.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"card mb-0"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group mb-1"},[_c('label',{staticClass:"fs-12 p-0 m-0 col-form-label",attrs:{"for":"name"}},[_vm._v("Rate ")]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.flatRate.rate),expression:"flatRate.rate"}],staticClass:"form-control",class:{
                  'is-invalid':
                    _vm.submitted && _vm.$v.flatRate.rate.$error,
                },attrs:{"type":"number"},domProps:{"value":(_vm.flatRate.rate)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.flatRate, "rate", $event.target.value)}}}),(_vm.submitted && !_vm.$v.flatRate.rate.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Rate is required ")]):_vm._e()])]),_c('div',{staticClass:"form-group mb-1"},[_c('label',{staticClass:"m-0 p-0 fs-12 col-form-label",attrs:{"for":"name"}},[_vm._v("Min Weight ")]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.flatRate.min_weight),expression:"flatRate.min_weight"}],staticClass:"form-control",class:{
                  'is-invalid':
                    _vm.submitted && _vm.$v.flatRate.min_weight.$error,
                },attrs:{"type":"text"},domProps:{"value":(_vm.flatRate.min_weight)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.flatRate, "min_weight", $event.target.value)}}}),(_vm.submitted && !_vm.$v.flatRate.min_weight.required
                  )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Min Weight is required ")]):_vm._e()])]),_c('div',[_c('div',{staticClass:"form-group mb-1"},[_c('label',{staticClass:"fs-12 p-0 m-0 col-form-label",attrs:{"for":"name"}},[_vm._v("Max Weight ")]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.flatRate.max_weight),expression:"flatRate.max_weight"}],staticClass:"form-control",class:{
                    'is-invalid':
                      _vm.submitted && _vm.$v.flatRate.max_weight.$error,
                  },attrs:{"type":"number"},domProps:{"value":(_vm.flatRate.max_weight)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.flatRate, "max_weight", $event.target.value)}}}),(_vm.submitted && !_vm.$v.flatRate.max_weight.required
                    )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Max Weight is required ")]):_vm._e()])])]),_c('div',{staticClass:"form-group mb-0"},[_c('label',{attrs:{"for":""}},[_vm._v(" Status ")]),_c('div',{staticClass:"form-group mb-0"},[_c('toggle-button',{attrs:{"value":true,"labels":{
                  checked: 'true',
                  unchecked: 'false',
                }},model:{value:(_vm.flatRate.active),callback:function ($$v) {_vm.$set(_vm.flatRate, "active", $$v)},expression:"flatRate.active"}})],1)])])])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group mb-1"},[_c('label',{staticClass:"m-0 p-0 fs-12 col-form-label",attrs:{"for":"name"}},[_vm._v("Min Value ")]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.flatRate.min_value),expression:"flatRate.min_value"}],staticClass:"form-control",class:{
                  'is-invalid':
                    _vm.submitted && _vm.$v.flatRate.min_value.$error,
                },attrs:{"type":"number"},domProps:{"value":(_vm.flatRate.min_value)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.flatRate, "min_value", $event.target.value)}}}),(_vm.submitted && !_vm.$v.flatRate.min_value.required
                  )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Min Value is required ")]):_vm._e()])]),_c('div',{staticClass:"form-group mb-1"},[_c('label',{staticClass:"fs-12 p-0 m-0 col-form-label",attrs:{"for":"name"}},[_vm._v(" Max Value ")]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.flatRate.max_value),expression:"flatRate.max_value"}],staticClass:"form-control",class:{
                  'is-invalid':
                    _vm.submitted && _vm.$v.flatRate.max_value.$error,
                },attrs:{"type":"number"},domProps:{"value":(_vm.flatRate.max_value)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.flatRate, "max_value", $event.target.value)}}}),(_vm.submitted && !_vm.$v.flatRate.max_value.required
                  )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Max Value is required ")]):_vm._e()])]),_c('div',[_c('div',{staticClass:"form-group mb-1"},[_c('label',{staticClass:"fs-12 p-0 m-0 col-form-label",attrs:{"for":"name"}},[_vm._v("Countries ")]),_c('div',{staticClass:"form-group"},[_c('multiselect',{attrs:{"multiple":true,"options":_vm.countriesList,"track-by":"name","label":"name"},model:{value:(_vm.countries),callback:function ($$v) {_vm.countries=$$v},expression:"countries"}})],1)])])])])]),_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group float-right mt-3"},[_c('button',{staticClass:"btn btn btn-add btn-primary"},[_vm._v("Submit")])])])
}]

export { render, staticRenderFns }