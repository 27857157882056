<template>
  <section class="edit-category">
    
    <div class="page-header">
      <h3 class="page-title">Add Flat Rate</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/settings/flat-rate" class="text-primary"> Flat Rate
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Add Flat Rate</li>
        </ol>
      </nav>
    </div>
    <form class="pt-2" @submit.prevent="addFlatRate">
      <div class="row">

        <div class="col-lg-6">
          <div class="card mb-0">
            <div class="card-body">
              <div class="form-group  mb-1">
                <label class=" fs-12 p-0 m-0 col-form-label" for="name">Rate
                </label>
                <div class="form-group">
                  <input type="number" v-model="flatRate.rate" class="form-control" :class="{
                    'is-invalid':
                      submitted && $v.flatRate.rate.$error,
                  }" />
                  <div v-if="submitted && !$v.flatRate.rate.required" class="invalid-feedback">
                    Rate is required
                  </div>
                </div>
              </div>
              <div class="form-group  mb-1">
                <label class=" m-0 p-0 fs-12 col-form-label" for="name">Min Weight </label>

                <div class="form-group">
                  <input type="text" v-model="flatRate.min_weight" class="form-control" :class="{
                    'is-invalid':
                      submitted && $v.flatRate.min_weight.$error,
                  }" />
                  <div v-if="submitted && !$v.flatRate.min_weight.required
                    " class="invalid-feedback">
                    Min Weight is required
                  </div>
                </div>

              </div>
              <div>
                <div class="form-group   mb-1">
                  <label class=" fs-12  p-0 m-0  col-form-label" for="name">Max Weight
                  </label>
                  <div class="form-group">
                    <input type="number" v-model="flatRate.max_weight" class="form-control" :class="{
                      'is-invalid':
                        submitted && $v.flatRate.max_weight.$error,
                    }" />
                    <div v-if="submitted && !$v.flatRate.max_weight.required
                      " class="invalid-feedback">
                      Max Weight is required
                    </div>
                  </div>

                </div>
              </div>
              <div class="form-group  mb-0">
                <label for=""> Status </label>
                <div class="form-group mb-0">
                  <toggle-button v-model="flatRate.active" :value="true" :labels="{
                    checked: 'true',
                    unchecked: 'false',
                  }" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card mb-3">
            <div class="card-body">
              <div class="form-group  mb-1">
                <label class=" m-0 p-0 fs-12 col-form-label" for="name">Min Value </label>

                <div class="form-group">
                  <input type="number" v-model="flatRate.min_value" class="form-control" :class="{
                    'is-invalid':
                      submitted && $v.flatRate.min_value.$error,
                  }" />
                  <div v-if="submitted && !$v.flatRate.min_value.required
                    " class="invalid-feedback">
                    Min Value is required
                  </div>
                </div>

              </div>
              <div class="form-group  mb-1">
                <label class=" fs-12 p-0 m-0 col-form-label" for="name"> Max Value
                </label>
                <div class="form-group">
                  <input type="number" v-model="flatRate.max_value" class="form-control" :class="{
                    'is-invalid':
                      submitted && $v.flatRate.max_value.$error,
                  }" />
                  <div v-if="submitted && !$v.flatRate.max_value.required
                    " class="invalid-feedback">
                    Max Value is required
                  </div>
                </div>
              </div>

              <div>
                <div class="form-group   mb-1">
                  <label class=" fs-12  p-0 m-0  col-form-label" for="name">Countries
                  </label>
                  <div class="form-group">
                    <multiselect v-model="countries" :multiple="true" :options="countriesList" track-by="name"
                      label="name"></multiselect>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group float-right mt-3">
            <button class="btn btn btn-add btn-primary">Submit</button>
          </div>
        </div>
      </div>
    </form>


  </section>
</template>

<script>
import { authApi } from "../../../api";
import { setingsApi } from "../../../api";

import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
export default {
  name: "edit-flatRate-modes",
  components: {
    
    Multiselect,
  },
  data() {
    return {
      
      flatRate: {
        rate: "",
        min_weight: "",
        max_weight: "",
        min_value: "",
        max_value: "",
        active: true,
      },
      countries: null,
      countriesList: [],
      submitted: false,
      aaa: [],
    };
  },
  validations: {
    flatRate: {
      rate: { required },
      min_weight: { required },
      max_weight: { required },
      min_value: { required },
      max_value: { required }
    },
  },
  mounted() {
    this.getCountries();
  },
  methods: {
    async getCountries() {
      
      const { data } = await authApi.countries();
      this.countriesList = data.data.data;
      
    },
    async addFlatRate() {

      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.flatRate.active === true) {
        this.flatRate.active = 1;
      } else {
        this.flatRate.active = 0;
      }
      this.countries.forEach((value, index) => {
        let a = value.id;
        if (!this.aaa.includes(a)) this.aaa.push(a);
      });
      this.flatRate.countries = this.aaa;

      
      const data = await setingsApi.addFlatRate({
        ...this.flatRate,
      });
      

      this.$router.push("/settings/flat-rate");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
      // alert("SUCCESS!! :-)\n\n" + JSON.stringify(this.flatRate));
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>